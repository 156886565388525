import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/indexEn',
    children: [
      {
        path: '/index',
        name: '首页',
        component: () => import('../views/Index.vue')
      },
      {
        path: '/indexEn',
        name: 'home',
        component: () => import('../views/IndexEn-editEnd.vue')
      },
      {
        path: '/framework',
        name: '云平台架构图',
        component: () => import('../views/Framework.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
