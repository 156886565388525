<template>
  <div id="app" class="layout">
    <div class="nav-list">
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid" @click="$router.push('/')">
          <img class="logo-img" src="../assets/logo.png" alt="" srcset="" />
          <!-- 头部按钮 -->
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <!-- 导航列表 -->
          <div class="collapse navbar-collapse" id="navbarNavDropdown">

            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">{{$route.path == '/index' ? '首页' : 'home'}}</el-menu-item>
              <!-- <el-submenu index="2">
                <template slot="title">我的工作台</template>
                <el-menu-item index="2-1">选项1</el-menu-item>
                <el-menu-item index="2-2">选项2</el-menu-item>
                <el-menu-item index="2-3">选项3</el-menu-item>
                <el-submenu index="2-4">
                  <template slot="title">选项4</template>
                  <el-menu-item index="2-4-1">选项1</el-menu-item>
                  <el-menu-item index="2-4-2">选项2</el-menu-item>
                  <el-menu-item index="2-4-3">选项3</el-menu-item>
                </el-submenu>
              </el-submenu>
              <el-menu-item index="3" disabled>消息中心</el-menu-item>
              <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
            </el-menu>
            <!-- <el-menu router  text-color="#38393a" :default-active="activeIndex" menu-trigger="click" class="el-menu-demo navbar-nav " mode="horizontal" @select="handleSelect">
              <el-menu-item class="nav-item" index="/">{{$route.path == '/index' ? '首页' : 'home'}}</el-menu-item> -->

            <!-- <el-submenu index="2">
                <template class="dropdown-menu"  slot="title">cloud platform</template>
                <el-menu-item index="/framework">Cloud Platform Architecture Diagram</el-menu-item>
              </el-submenu> -->
            <!-- <el-menu-item class="nav-item" index="3">XXXXXXXX</el-menu-item>
              <el-menu-item class="nav-item" index="4">XXXXXXXXX</el-menu-item> -->
            <!-- </el-menu> -->

            <!-- <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/index" class="nav-link active" aria-current="page" href="#">首页</router-link>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  云平台
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link to="/framework" class="nav-link active" aria-current="page" href="#">云平台架构</router-link>
                  </li>
                  <li><a class="dropdown-item" href="#">XXXXXXXXX</a></li>
                  <li>
                    <a class="dropdown-item" href="#">XXXXXXXXX</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  云平台
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link to="/framework" class="nav-link active" aria-current="page" href="#">云平台架构</router-link>
                  </li>
                  <li><a class="dropdown-item" href="#">XXXXXXXXX</a></li>
                  <li>
                    <a class="dropdown-item" href="#">XXXXXXXXX</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  云平台
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link to="/framework" class="nav-link active" aria-current="page" href="#">云平台架构</router-link>
                  </li>
                  <li><a class="dropdown-item" href="#">XXXXXXXXX</a></li>
                  <li>
                    <a class="dropdown-item" href="#">XXXXXXXXX</a>
                  </li>
                </ul>
              </li>
            </ul> -->
          </div>
        </div>
      </nav>
    </div>

    <!-- 占位符 -->
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>

    <div class="foot-border container"></div>

    <!-- 页脚 -->
    <!-- qr-code -->
    <div class="footer">
      <div class="foot">
        <div class="qr-code">
          <img src="../assets/qr-code.jpg" alt="">
        </div>
        <div class="code-title">扫描二维码关注见达睡眠公众号</div>
        <div class="code-title"><a href="http://beian.miit.gov.cn/">粤ICP备2023051598号</a></div>
        <div class="code-title">Copyright © 2014 - {{dataYear}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  data() {
    return {
      route: null,
      activeIndex: '1',
      dataYear: ''
    }
  },
  mounted() {
    // console.log(this.$route.path); // 当前路由路径
    // console.log(this.$route.params); // 路由参数
    // console.log(this.$route.query); // 查询参数
    const currentDate = new Date();
    this.dataYear = currentDate.getFullYear();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}; 
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
  background-color: #fff;
  .nav-list {
    width: 94%;
    margin: 0 auto;
    .logo-img {
      margin-right: 5em;
    }
    .collapse {
      margin: 0 auto;
    }
  }
  .nav-top {
    border: 1px solid black;
  }

  .foot-border {
    margin-top: 1em;
    background: black;
    height: 1px;
    width: 90%;
    transform: scaleY(0.5);
  }

  .footer {
    margin-top: 1em;
    width: 100%;
    .foot {
      width: 15em;
      margin: 0 auto;
      .qr-code {
        margin: 0 auto;
        width: 6em;
        height: 6em;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .code-title {
        text-align: center;
        font-size: 0.7em;
        a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}

/* 在小于或等于 992 像素的屏幕上 */
@media screen and (max-width: 992px) {
  .logo-img {
    width: 40%;
    height: 40%;
  }
}

// navbar的字体颜色
.nav-link {
  color: #000;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

>>> .el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
>>> .el-menu--horizontal > .el-submenu .el-submenu__title,
>>> .el-menu--horizontal > .el-menu-item {
  // color: #000;
  font-size: 1em !important;
}
</style>
